@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: ReckLess;
  src: url('./assets/fonts/RecklessNeue-Regular.ttf')
}

@font-face {
  font-family: Biliktu;
  src: url('./assets/fonts/biliktu.ttf')
}

body {
  margin: 0;
  font-family: Biliktu, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.5rem;
  line-height: 1.7rem;
  max-width: 100%;
  overflow-x: hidden;
}

@media only screen and (max-width: 990px) {
  h1, h2 {
    font-size: 50px !important;
    line-height: 60px !important;
  }

  body {
    font-size: 18px;
    line-height: 21.6px;
  }

  #agenda .swiper {
    height: 300px !important;
  }
}

h1, h2 {
  font-family: ReckLess;
  font-size: 6rem;
  line-height: 5rem;
}

h3 {
  font-family: ReckLess;
  font-size: 4rem;
  line-height: 3rem;
}

h4 {
  font-family: ReckLess;
  font-size: 2rem;
  line-height: 3rem;
}

code {
  font-family: Biliktu, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide-vertical {
  animation: hidden_vertical 1s 2s forwards !important;
  opacity: 1;
}

@keyframes hidden_vertical {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.slide-up {
  animation: slide-up 0.9s cubic-bezier(0.65, 0, 0.35, 1) both
}

@keyframes slide-up {
  0% {
    transform: translateY(100px)
  }

  100% {
    transform: translateY(0)
  }
}

.swiper-slide {
  height: auto !important;
}

#contact input::placeholder, #contact textarea::placeholder {
  color: #F7E1DA;
}

input[type=checkbox] {
  position: relative;
  cursor: pointer;
}

input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  border: 3px solid #F7E1DA;
  background-color: #173930;
}

input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 15px;
  height: 30px;
  border: solid #F7E1DA;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0px;
  bottom: 5px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.ball-btn {
  background-color: #EAC7B7;
  mask: url("./assets/images/ball-btn.svg") no-repeat center;
}

.timeline {
  background-image: linear-gradient(#C1C9C6 33%, rgba(255, 255, 255, 0) 10%);
  background-position: right;
  background-size: 1px 35px;
  background-repeat: repeat-y;
}

/* The flip box container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-box {
  perspective: 1000px;
  overflow: hidden;
  /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-box-front {
  color: black;
}

/* Style the back side */
.flip-box-back {
  transform: rotateY(180deg);
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  bottom: 0 !important;
}

.calendar-icon::-webkit-calendar-picker-indicator {
  filter: invert(1);
  display: none;
}

.agenda-swiper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0)
}